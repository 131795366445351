<template>
  <base-section
    id="simplified-field-services"
  >
    <base-section-heading
      :title="$t('SimplifiedFieldServices.title')"
    >
      {{ $t('SimplifiedFieldServices.text') }}
    </base-section-heading>
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container
        fluid
        class="justify-center"
      >
        <v-row>
          <v-col
            v-for="card in $t('SimplifiedFieldServices.cards')"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="center"
              v-bind="card"
              class="info-container"
            />
            <v-card-actions class="justify-center">
              <base-btn
                class="mb-12"
                color="blue"
                outlined
                @click="changePage(card.path)"
              >
                {{ $t('SimplifiedFieldServices.btnText') }}
              </base-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSimplifiedFieldServices',
    methods: {
      changePage (option) {
        return this.$router.replace({ name: option })
      },
    },
  }
</script>
